import * as React from "react";
import Layout from "../../components/layout/Layout";

export default function index() {
  return (
    <div>
    <Layout>
      Privacy Policy
    </Layout>
    </div>
  )
}

